<template>
  <div class="page">
    <div class="fab" @click="clickAdd">
      <van-icon name="plus" color="white" />
    </div>
    <van-empty description="还没有数据哦" v-if="!loading && finished && list.length === 0" style="position: fixed;top: 30vh;left: 0;right: 0;"/>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-collapse v-model="activeGroup" accordion>
          <van-collapse-item v-for="item in userCabinetGroup" :key="item.title" :name="item.title">
            <template #title>
              <div>
                {{ item.title }}
                <van-tag style="margin-left: 10px" plain type="primary">{{ item.users.length }}</van-tag>
                <van-tag style="margin-left: 10px" plain type="danger" v-show="item.users.filter(it => it.overtime).length > 0">{{ item.users.filter(it => it.overtime).length }}</van-tag>
              </div>
            </template>
            <van-swipe-cell v-for="info in item.users" :key="info.deviceId" right-width="120">
              <van-cell  :title="info.username" center :label="'今日单量：' + info.todayOrderCount">
                <template #default>
                  <label style="color: red" v-if="info.overtime">{{info.exp}}</label>
                  <label v-else>{{info.exp}}</label>
                </template>
<!--                <template #label>-->
<!--                  <label>今日单量：{{info.todayOrderCount }}</label><br>-->
<!--                  <label>{{ info.bindDeviceName }}</label>-->
<!--                </template>-->
              </van-cell>
              <template #right>
                <div style="display: flex;height: 100%">
                  <div class="del" @click="onEdit(info)" style="background-color: dodgerblue">编辑</div>
                  <div class="del" @click="onDelete(info)">删除</div>
                </div>
              </template>
            </van-swipe-cell>
          </van-collapse-item>
        </van-collapse>

<!--        <van-cell v-for="item in list" :key="item.deviceId" :title="item.name" :label="'今日单量：' + item.todayOrderCount" />-->
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="showAdd" style="width: 80%;border-radius: 10px;padding-top: 20px">
      <div style="text-align: center;font-weight: bold">{{ showEdit ? '编辑' : '添加'}}用户信息</div>
      <van-form @submit="onSubmit">
        <van-field name="username" v-model="form.username" label="名称" :rules="[{required: true, message: '请填写'}]"/>
        <van-field name="bindDeviceName" v-model="form.bindDeviceName" label="设备" :rules="[{required: true, message: '请填写'}]" readonly @click="showCabinet = true"/>
        <van-field name="token" v-model="form.token" label="token"  :rules="[{required: true, message: '请填写'}]"/>
        <van-field name="maxCountPerDay" v-model="form.maxCountPerDay" label="每日最大单量"  :rules="[{required: true, message: '请填写'}]"/>
        <div style="display: flex;justify-content: center;align-items: center">
          <van-button native-type="submit" round type="info" style="width: 200px;margin: 20px 0">提交</van-button>
        </div>
      </van-form>
    </van-popup>

    <van-popup v-model="showCabinet" position="bottom">
      <van-picker
          show-toolbar
          :columns="cabinetList.map(it => it.name)"
          @confirm="onConfirmCabinet"
          @cancel="showCabinet = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "user",
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      showAdd: false,
      showEdit: false,
      showCabinet: false,
      form: {username: '', token: '', bindDeviceId: '', bindDeviceName: '', maxCountPerDay: 10},
      selectedItem: null,
      cabinetList: [],
      activeGroup: '',
      userCabinetGroup: []
    }
  },
  created() {
    this.loadRiderCabinetData()
  },
  methods: {
    clickAdd() {
      this.showAdd = true
      this.showEdit = false
      this.form = {username: '', token: '', bindDeviceId: '', bindDeviceName: '', maxCountPerDay: 10}
    },
    loadRiderCabinetData() {
      this.$http.get('mock/cabinet').then(res => {
        this.cabinetList = res.data
      })
    },
    onConfirmCabinet(value) {
      this.showCabinet = false
      for (let cabinet of this.cabinetList) {
        if (cabinet.name === value) {
          this.form.bindDeviceId = cabinet.deviceId
          this.form.bindDeviceName = cabinet.name
          break
        }
      }
    },
    onSubmit(values) {
      console.log(values)
      values.bindDeviceId = this.form.bindDeviceId
      this.$toast.loading()
      if (this.showEdit) {
        if (this.selectedItem) values.id = this.selectedItem.id
        this.$http.put('mock/userInfo', values).then(res => {
          this.$toast.clear(true)
          this.showAdd = false
          this.showEdit = false
          if (res.data === 'good') {
            this.selectedItem = null
            this.onLoad()
          }
        })
      } else {
        this.$http.post('mock/userInfo', values).then(res => {
          this.$toast.clear(true)
          this.showAdd = false
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }
    },
    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      this.$http.get('mock/userInfo').then(res => {
        this.loading = false
        res.data.forEach(it => {
          const date = new Date(JSON.parse(window.atob(it.token.split('.')[1])).exp * 1000)
          it.date = date
          it.exp = `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
          it.overtime = date.getTime() < Date.now()
        })
        res.data.sort((a, b) => {
          return a.date - b.date
        })

        let group = new Map()
        res.data.forEach(it => {
          if (group.has(it.bindDeviceName)) {
            group.get(it.bindDeviceName).push(it)
          } else {
            group.set(it.bindDeviceName, [it])
          }
        })
        let groupArray = []
        group.forEach((value, key) => {
          groupArray.push({title: key, users: value})
        })
        this.userCabinetGroup = groupArray
        this.list = res.data
        this.finished = true
        this.refreshing = false
        this.$bus.$emit('data-refresh', {type:'user', amount:this.list.length})
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.finished = true
        this.refreshing = false
      })
    },
    onDelete(item) {
      this.$dialog({title:'确定删除用户 ' + item.username, showCancelButton: true}).then(() => {
        this.$toast.loading({mask: true})
        this.$http.delete('mock/userInfo/' + item.id).then(res => {
          this.$toast.clear(true)
          if (res.data === 'good') {
            this.onLoad()
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    },
    onEdit(item) {
      this.showAdd = true
      this.showEdit = true
      this.selectedItem = item
      this.form.username = item.username
      this.form.bindDeviceId = item.bindDeviceId
      this.form.bindDeviceName = item.bindDeviceName
      this.form.token = item.token
      this.form.maxCountPerDay = item.maxCountPerDay
    }
  }
}
</script>

<style scoped>
/deep/ .van-list__finished-text {
  margin-bottom: 100px;
}
.label-class {
  /*color: red;*/
  /*width: 60vw;*/
  /*background-color: #42b983;*/
}
.del {
  background-color: red;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.all-room {
  height: calc(100% - 50px);
}
</style>
